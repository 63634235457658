.bg {
    position: fixed;
    top: 25vw;
    z-index: 0;
    height: 100%;
    width: 100%;
    background-image: url('/public/sea.png');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    filter: brightness(0.32);
}

.cont {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.ship {
    width: 50%;
    z-index: 6 !important;
}

.ship2 {
    width: 50%;
    z-index: 6 !important;

}

.ship img {
    left: -100%;
    top: 20vw;
    width: 50% !important;
    position: absolute;
    filter: brightness(1.2);
    animation: moveAndFlip 10s linear 1;
}

.ship2 img {

    left: 45%;
    top: 20vw;
    width: 50% !important;
    position: absolute;
    filter: brightness(1.2);
    animation: moveAndFlip2 10s linear 1;


}

.ship_ar {
    width: 50%;
    z-index: 6 !important;
}

.ship2_ar {
    width: 50%;
    z-index: 6 !important;

}

.ship_ar img {
    right: -100%;
    top: 20vw;
    width: 50% !important;
    position: absolute;
    filter: brightness(1.2);
    animation: moveAndFlip_ar 10s linear 1;
}

.ship2_ar img {

    right: 45%;
    top: 20vw;
    width: 50% !important;
    position: absolute;
    filter: brightness(1.2);
    animation: moveAndFlip2_ar 10s linear 1;


}

@keyframes moveAndFlip {
    0% {
        left: -50%;
        transform: rotate(180deg);
    }

    100% {
        left: 200%;
        transform: rotate(180deg);

    }
}

@keyframes moveAndFlip2 {

    0% {

        left: 250%;
        opacity: 0;

    }

    100% {
        left: 45%;
        opacity: 1;

    }
}




@keyframes moveAndFlip_ar {
    0% {
        right: -50%;

    }

    100% {
        right: 200%;

    }
}

@keyframes moveAndFlip2_ar {

    0% {

        right: 200%;
        opacity: 0;

    }

    100% {
        right: 45%;
        opacity: 1;

    }
}

.finance {
    width: 40%;
    margin-left: 15%;
}

.mainTitleWhite {
    color: #ffffff;

    font-family: Quicksand;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    /* 115.2px */
}

h1 {
    width: 90%;
    margin-left: 80px;
}

.mainTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;


}

p {
    color: white;
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    margin-top: 50px !important;
    width: 90%;
    margin-left: 80px;
}

.subTitleYellow2 {
    color: #EBD5A8;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    flex-direction: row-reverse;
    gap: 1rem;

}

.subTitleYellow2 h1 {
    margin: 0;
    color: #EBD5A8;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

}

.subTitleYellow2 h2 {
    color: #ffffff;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.subTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

}


.subTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.subMainTitleWhite2 {
    color: #ffffff;
    font-family: Cairo Light;
    font-size: 20px;
}

.subMainTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 20px;
}

.subMainTitleWhite2 h2 {
    font-weight: 400;
    font-style: normal;
    font-family: Cairo Light;
    font-size: 20px;

}

.subMainTitleWhite h2 {
    font-weight: 400;
    font-style: normal;
    font-family: Quicksand;
    font-size: 20px;

}

.count {
    margin-left: 50px;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #00000017, #53535380);
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    color: #fff;
    z-index: 1;

}


.homeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    height: 100vh;
}

.box {
    padding: 0px 30px 0px 30px;
}

.headline h1 {
    margin: 0;
    width: 100%;
    color: var(--White-600, #FFF);
    font-family: Quicksand;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-align: left;
    width: auto;

}

.headline2 h1 {
    margin: 0;
    width: 100%;
    color: var(--White-600, #FFF);
    font-family: Cairo Light;

    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: right;
    width: auto;

}

.headline2 h2 {
    color: #EBD5A8;
    font-family: Cairo Light;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: right;
    width: auto;
    margin: 0 !important;

}

.headline h2 {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-align: left;
    width: auto;
    margin: 0 !important;
    animation: fadeInOut 2s ease-in;
    /* animation-delay: s; */

}

.des {
    display: flex;
}

.des p {
    color: white;
    font-family: Quicksand;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    width: 100%;
    margin: 0 !important;
    margin-top: 3% !important;
    animation: fadeInOut 2s ease-in;
}

.des2 {
    display: flex;
    flex-direction: row-reverse;
}

.des2 p {
    color: white;
    font-family: Cairo Light;
    text-align: right;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    width: 70%;
    margin: 0 !important;
    margin-top: 1% !important;
    line-height: 160%;


}

.count {
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10%;
}

.count2 {
    margin: 0 !important;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 10%;
}

.numbers {
    margin: 0 !important;
    display: flex;
    margin-top: 5% !important;
}

.numbers2 {
    margin: 0 !important;
    display: flex;
    margin-top: 3% !important;
}



.numbCont {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.subTitleYellow h2 {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.subTitleYellow h1 {
    margin: 0;

    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

}

.fadeInFirstLIne {
    animation: fadeOut 2s ease;
    /* animation-delay: 3000ms; */
}

.fadeInSecond {
    animation: fadeOut 2.5s ease;
    /* animation-delay: 3000ms; */
}

.fadeInThird {
    animation: fadeOut 2s ease;
    /* animation-delay: 3000ms; */
}


@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media(max-width:1024px) {
    .overlay {
        display: none;
    }
}

@media (max-height: 708px) {
    .cont {
        margin-top: 8%;

    }
}
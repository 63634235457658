.section {
    /* padding-right: 30px; */
    /* padding-left: 30px; */
    margin-right: 3rem !important;
    margin-bottom: 3rem;
}

.MobileCont {
    display: block;
    height: 100vh;
    overflow-y: auto;
    background: linear-gradient(179deg, black 0%, rgba(49.46, 50.48, 52.52, 0.70) 61%, rgba(51.84, 51.84, 51.84, 0.60) 70%, rgba(69.69, 69.69, 69.69, 0.40) 85%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    overflow-x: hidden;
}

.MainTitleWhite {
    color: #FFF;
    font-family: Quicksand;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    width: 150%;
    display: block;

}

.MainTitleYellow {
    color: var(--White-600, #EBD5A8);
    font-family: Quicksand;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    display: block;

}

.subParagraph {
    color: #FFF;
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin: 0 !important;
    width: 120%;
}

.subParagraph2 {
    color: #FFF;
    font-family: Cairo Light;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin: 0 !important;
    width: 100%;
}

.coin {
    background-image: url('../../public/MobileCoin.png');
    background-position: right center;
    background-repeat: no-repeat;
}

.YellowNum {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 40.49px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.WhiteNum {
    color: #FFF;
    font-family: Quicksand;
    font-size: 40.49px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Num h2 {
    color: #FFF;
    font-family: Quicksand;
    font-size: 15.184px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.SliderCont {
    border: #EBD5A8 1px dashed;
    height: 33px;
    margin: auto;
    overflow: hidden;
    position: absolute;
    width: 110%;
    left: -5%;
}

.SliderCont::before,
.SliderCont::after {
    content: "";
    height: 100px;
    /* This seems unnecessary for the slider functionality */
    position: absolute;
    width: 200px;
    /* This seems unnecessary for the slider functionality */
    z-index: 2;
}

.SliderCont::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.SliderCont::before {
    left: 0;
    top: 0;
}

.SliderCont .SliderTracker {
    animation: scroll 15s linear infinite;
    /* Adjusted animation duration to 10s */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-100%));
        /* Adjusted to translate by the width of the container */
    }
}

.SliderCont .SlideELe {
    margin-left: 15px;
    margin-right: 15px;
    /* Adjust width as needed */
    width: 102px;
}

.SlideELe2 {
    margin-left: 15px;
    margin-right: 15px;
    /* Adjust width as needed */
    width: 102px;
}

.SliderCont h1 {
    color: rgba(219, 197, 152, 0.60) !important;
    font-family: Quicksand;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.SlideELe2 h1 {
    font-family: Cairo Light;
}

.SlideELe2 {
    flex: 0 0 auto;
    margin-right: 15px;
    justify-content: center;
    align-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 5px;
    /* Adjust as needed */
}

.SlideELe {
    flex: 0 0 auto;
    margin-right: 15px;
    /* Adjust as needed */
}

/* @media(min-width:768px) {

    

    .hero {
        height: 800px
    }

} */



.mainTitleWhite {
    color: #FFF;
    font-family: Quicksand;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    /* 2.7rem */
}

.MainTitleWhite2 {
    color: #FFF;
    font-family: Cairo Light;
    text-align: right;
    width: 100%;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    /* 2.7rem */
}

.mainTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

}

.MainTitleYellow2 {
    color: #EBD5A8;
    font-family: Cairo Light;
    text-align: right;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 100%;

}

.serBox {
    width: 42vw;
    height: 42vw;
    flex-shrink: 0;
    margin-right: 15px;
    box-shadow: 8px 8px 4px #00000040;
    background-color: rgba(23, 23, 25, 1);
    --d: 15px;
    --b: 4px;
    --c: orange;
    --gc: var(--c) calc(50% + var(--b)*0.707), #0000 0;
    --gl: var(--c), #0000 40% 60%, var(--c);
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8%;
    padding-top: 18%;
    flex-direction: column;
}


.serBox:hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

.serBox2 {
    width: 42vw;
    height: 42vw;
    flex-shrink: 0;
    margin-right: 15px;
    box-shadow: 8px 8px 4px #00000040;
    background-color: rgba(23, 23, 25, 1);
    --d: 15px;
    --b: 4px;
    --c: orange;
    --gc: var(--c) calc(50% + var(--b)*0.707), #0000 0;
    --gl: var(--c), #0000 40% 60%, var(--c);
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 8%;
    padding-top: 18%;
    flex-direction: column;
}


.serBox2:hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

svg {
    width: 50px;
    height: 50px;
}

h5 {
    color: #FFF !important;
    font-family: Quicksand;
    font-size: 2.7vh !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px !important;
}


.NumYellow {
    color: var(--White-600, #EBD5A8);
    font-family: Quicksand;
    font-size: 2.80663rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.NumWhite {
    color: var(--White-100, #FFF);
    font-family: Quicksand;
    font-size: 2.105rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width:1024px) {
    .hero {
        /* height: 685px !important; */
        margin-top: 20%;
        margin-bottom: 3rem;
    }

    .MainTitleWhite,
    .MainTitleYellow {
        font-size: 90px;
    }

    .subParagraph {
        font-size: 25px;
        line-height: 34px;

    }
}

@media (max-width:768px) {
    .hero {
        margin-top: 30%;
        margin-bottom: 6rem;
        /* height: 700px !important; */
    }

    .textContent {
        display: inline-flex;
        margin-bottom: 20px;
    }

    .mainTitleYellow {
        margin-left: 15px;
    }

    .MainTitleWhite,
    .MainTitleYellow {
        font-size: 60px;
    }

    .subParagraph {
        font-size: 18px;
        line-height: 30px;

    }

    .carousel-indicators {
        bottom: 110px;
    }

    .section {
        margin-right: 0.5rem !important;
    }

    h5 {
        font-size: 16px !important;
    }
}

@media (max-width:300px) {
    .hero {
        /* height: 615px !important; */

    }

    .MainTitleWhite,
    .MainTitleYellow {
        font-size: 45px;
    }

    .subParagraph {
        font-size: 16px;
        line-height: 34px;

    }

    .section {
        margin-right: 0.5rem !important;
    }
}

.mainTitleWhite {
    color: #FFF;
    font-family: Quicksand;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    /* 2.7rem */
}

.mainTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 2.25em;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;

}

.serBox {
    width: 42vw;
    height: 42vw;
    flex-shrink: 0;
    margin-right: 15px;
    box-shadow: 8px 8px 4px #00000040;
    background-color: rgba(23, 23, 25, 1);
    --d: 15px;
    --b: 4px;
    --c: orange;
    --gc: var(--c) calc(50% + var(--b)*0.707), #0000 0;
    --gl: var(--c), #0000 40% 60%, var(--c);
    clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8%;
    padding-top: 18%;
    flex-direction: column;
}


.serBox:hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
}

svg {
    width: 50px;
    height: 50px;
}

h5 {
    color: #FFF !important;
    font-family: Quicksand;
    font-size: 4vw !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px !important;
    margin-right: 16px;
}


.NumYellow {
    color: var(--White-600, #EBD5A8);
    font-family: Quicksand;
    font-size: 2.80663rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.NumWhite {
    color: var(--White-100, #FFF);
    font-family: Quicksand;
    font-size: 2.105rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.Coinbg {
    width: 30%;
    height: auto;
    margin-right: 10%;
    display: flex;
    justify-content: center;
}

.Coinbg img {
    width: auto;
    height: 100%;
    transition: transform 0.5s ease;
    position: relative;
    animation: moveCoinbg 0.6s linear 1;
    top: -25%;

}

/* 
.fadeInFirstLIne {
    animation: fadeOut 0.5s ease;
}

.fadeInSecond {
    animation: fadeOut 0.9s ease;
} */
/* 
.fadeInThird {
    animation: fadeOut 5s ease;
    animation-delay: 1900ms;
} */


.cont {
    margin-top: 20%;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

@keyframes moveCoinbg {
    from {
        top: -100vh;
        transform: scaleX(-1);

    }

    to {
        transform: scaleX(1);
        top: -25vh;
    }

    /* 100% {
        top: 0%;
        transform: scaleX(1);


    } */
}


.finance {
    width: 40%;
    margin-left: 10%;
}

/* .mainTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 128px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    
} */

.headline h1 {
    margin: 0;
    width: 100%;
    color: var(--White-600, #FFF);
    font-family: Quicksand;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-align: left;
    width: auto;

}

.headline2 h1 {
    margin: 0;
    width: 100%;
    color: var(--White-600, #FFF);
    font-family: Cairo Light;

    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: right;
    width: auto;

}

/* 
h1 {
    width: 90%;
    margin-left: 3%;
} */

.headline h2 {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    text-align: left;
    width: auto;
    margin: 0 !important;
}

.headline2 h2 {
    color: #EBD5A8;
    font-family: Cairo Light;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: right;
    width: auto;
    margin: 0 !important;

}

.fadeInThird h2 .fadeInThird p {
    animation: fadeOut 1.5s ease-out;
    animation-delay: 2s;

}

.des {
    display: flex;
}

.des p {
    color: white;
    font-family: Quicksand;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    width: 70%;
    margin: 0 !important;
    margin-top: 3% !important;


}

.des2 {
    display: flex;
    flex-direction: row-reverse;
}

.des2 p {
    color: white;
    font-family: Cairo Light;
    text-align: right;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    width: 70%;
    margin: 0 !important;
    margin-top: 3% !important;
    line-height: 160%;


}

.homeContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    margin-left: 11% !important;
    margin-right: 11% !important;
    margin-top: 11% !important;

}

.subTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

}

.subTitleYellow2 {
    color: #EBD5A8;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    flex-direction: row-reverse;
    gap: 1rem;

}

.subMainTitleWhite h2 {
    font-weight: 400;
    font-style: normal;
    font-family: Quicksand;
    font-size: 20px;

}

.subMainTitleWhite2 h2 {
    font-weight: 400;
    font-style: normal;
    font-family: Cairo Light;
    font-size: 20px;

}

.subTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
}



.subMainTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 20px;
}

.subMainTitleWhite2 {
    color: #ffffff;
    font-family: Cairo Light;
    font-size: 20px;
}

.count {
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10%;
}

.numbers {
    margin: 0 !important;
    display: flex;
    margin-top: 5% !important;
}

.numbers2 {
    margin: 0 !important;
    display: flex;
    margin-top: 8% !important;
}


.numbCont {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.subTitleYellow h2 {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.subTitleYellow2 h2 {
    color: #ffffff;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.count {
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10%;
}

.count2 {
    margin: 0 !important;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 10%;
}

.numbers {
    margin: 0 !important;
    display: flex;
    margin-top: 5% !important;
}



.numbCont {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.subTitleYellow h2 {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.subTitleYellow2 h2 {
    color: #ffffff;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;

}

.subTitleYellow h1 {
    margin: 0;
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

}

.subTitleYellow2 h1 {
    margin: 0;
    color: #EBD5A8;
    font-family: Cairo Light;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;

}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
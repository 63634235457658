.sliderCont {
  width: 100%;
  height: 100vh;
  margin: auto;


}

.sliderContainer {
  width: 100%;
  height: 100vh;
  margin: auto;

}

.sliderContent {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10%;


}

.sliderText {
  height: 100%;
  /* padding-left: 17%; */
  width: 37%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;
}

.sliderImage {
  /* flex-grow: 1; */
  width: 41%;
  height: auto;

  /* order: 2; */
  /* display: flex; */
}

.sliderImage img {
  margin-left: 5%;
  width: 66%;
  z-index: 2;


}

.head1 {
  color: var(--White-100, #FFF);

  font-family: Quicksand;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  /* 57.6px */
  margin-left: 3%;
}

.head11 {
  color: var(--White-100, #FFF);
  text-align: right;
  font-family: Cairo Medium;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 57.6px */
  margin-left: 3%;
}

.head2 {
  color: var(--White-100, #EBD5A8);

  font-family: Quicksand;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  /* 57.6px */
  margin-left: 3%;
}

.head22 {
  color: var(--White-100, #EBD5A8);
  text-align: right;
  font-family: Cairo Medium;
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  /* 57.6px */
  margin-left: 3%;
}

.disCont {
  display: flex;
  margin-top: -35px;
  width: 100%;
}

.disCont p {
  color: var(--White-100, rgb(216, 213, 213));
  width: 100%;
  margin-left: 3%;
  margin-top: 3%;
  font-family: Quicksand;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal
}


.disCont2 {
  display: flex;
  margin-top: -35px;
  width: 100%;
}

.disCont2 p {
  color: var(--White-100, rgb(216, 213, 213));
  width: 100%;
  margin-left: 3%;
  margin-top: 3%;
  text-align: right;
  font-family: Cairo Light;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal
}

.pageContainer {
  background-image: url('/public/BG\ financial.png');
  /* Specify the path to your background image */
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* Ensure the background covers the entire viewport height */

}

.framesContainer {
  display: flex;
  margin: 40px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20%;
}

.frame {

  z-index: 3;
  background-color: #171719;
  color: white;
  /* padding: 20px; */
  text-align: center;
  width: 365px;
  height: 60px;

  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame2 {

  z-index: 3;
  background-color: #171719;
  color: white;
  /* padding: 20px; */
  text-align: center;
  width: 365px;
  height: 60px;

  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame h3 {
  color: var(--White-500, #F1E3C5);
  text-align: center;
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  /* Adjust this value as needed */
}


.frame2 h3 {
  color: var(--White-500, #F1E3C5);
  text-align: center;
  font-family: Cairo Light;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  /* Adjust this value as needed */
}

.separator {
  width: 100%;
  display: flex;
  opacity: 1;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #171719;
  align-self: center;
  margin-bottom: 0;
  filter: drop-shadow(1px 0px 4px rgba(219, 197, 152, 0.60));
  z-index: 2;
}

.shapecontainer {
  display: flex;
  flex-direction: column;
  height: 83px;
  align-items: center;

}

.numberingshape {
  margin-top: -7px;
  margin-left: -45px;
  /* Adjust the spacing between the numbering shape and the separator */
  z-index: 3;
  width: 45px;
  /* Adjust the width of the shape as needed */
  height: 45px;
  /* Adjust the height of the shape as needed */
  background-color: #171719;
  /* Adjust the background color as needed */
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
  filter: drop-shadow(1px 0px 4px rgba(219, 197, 152, 0.60));
  position: absolute;
}

.numberingshape2 {
  margin-top: -7px;
  margin-left: 395px;
  /* Adjust the spacing between the numbering shape and the separator */
  z-index: 3;
  width: 45px;
  /* Adjust the width of the shape as needed */
  height: 45px;
  /* Adjust the height of the shape as needed */
  background-color: #171719;
  /* Adjust the background color as needed */
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
  filter: drop-shadow(1px 0px 4px rgba(219, 197, 152, 0.60));
  position: absolute;
}

.numbline {
  width: 395px;
  align-self: start;
}

.number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.slider1 {
  transition: all 1s ease;
  transform: translateX(100%),
}

.slidersbuttons {
  display: flex;
  margin-top: -28vh;
  margin-left: 86%;
  gap: 1%;

}

.leftarrow {
  background: url('/public/leftarrow.svg');
  width: 36px;
  /* Adjust the width and height based on your SVG dimensions */
  height: 36px;
  border: none;
  /* Remove default button border */
  cursor: pointer;
  /* Add a pointer cursor for better UX */
  z-index: 2;
}

.rightarrow {
  background: url('/public/rightarrow.svg');
  width: 36px;
  /* Adjust the width and height based on your SVG dimensions */
  height: 36px;
  border: none;
  /* Remove default button border */
  cursor: pointer;
  /* Add a pointer cursor for better UX */
  z-index: 2;
}

.pageNumber {
  color: var(--Gray-300, #8D8F91);

  /* Quick Sand/24/Medium */
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: transparent;
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 6px !important;
}

.selectedPage {

  color: var(--White-200, #FCF8F1);
  /* Quick Sand/24/Medium */
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: transparent;
}

@media (max-width: 1024px) {

  /* Styles for devices with a maximum width of 600px (mobile devices) */
  .mobPageContainer {
    display: flex !important;
  }

  .pageContainer {
    display: none !important;
  }
}

@media (min-width: 1024px) {

  .mobPageContainer {
    display: none !important;
  }

  .pageContainer {
    display: flex !important;

  }
}

.mobPageContainer {
  position: relative;
  background-image: url('/public/image2.png');
  display: flex;
  flex-direction: column;
}

.submobPageContainer {
  position: relative;
  z-index: 1;
  /* Ensure content appears above the background image */
  margin-top: 17vh;
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mobTitle h1 {
  color: var(--White-100, #FFF);
  width: 100%;
  /* Quick Sand/36/Regular */
  font-family: Quicksand;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mobTitle2 h1 {
  color: var(--White-100, #FFF);
  width: 100%;
  /* Quick Sand/36/Regular */
  font-family: Cairo Light;
  text-align: right;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mobimageCont {
  width: 100%;
  height: 12%;
}

.mobimageCont img {
  width: 100%;
  height: 100%;
}

.mobText {
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;

  width: 100%;
  display: flex;
  flex-direction: column;
}

.mobHead1 {
  color: var(--White-100, #FFF);

  font-family: Quicksand;
  font-size: 1.5rem;

  font-style: normal;
  font-weight: 400;
  line-height: 90%;

}

.mobHead12 {
  color: var(--White-100, #FFF);

  font-family: Cairo Light;
  text-align: right;
  font-size: 1.5rem;

  font-style: normal;
  font-weight: 400;
  line-height: 90%;

}

.mobHead2 {
  color: var(--White-100, #EBD5A8);

  font-family: Quicksand;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;


}

.mobHead22 {
  color: var(--White-100, #EBD5A8);

  font-family: Cairo Light;
  text-align: right;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;


}

.mobDisCont {
  display: flex;

}

.mobDisCont p {
  color: var(--White-100, #FFF);
  padding-top: 3%;
  /* Quick Sand/18/Regular */
  font-family: Quicksand;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
  width: 100%;
}

.mobDisCont2 {
  display: flex;

}

.mobDisCont2 p {
  color: var(--White-100, #FFF);
  padding-top: 3%;
  /* Quick Sand/18/Regular */
  font-family: Cairo Light;
  text-align: right;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
  width: 100%;
}

.mobshapecontainer {
  display: flex;
  flex-direction: column;
  height: 83px;
  align-items: center;

}

.mobnumbline {
  width: 93%;
  align-self: start;
}

.mobnumbline2 {
  width: 100%;
  align-self: start;
  display: flex;
  flex-direction: row-reverse;
}

.mobnumberingshape {


  /* Adjust the spacing between the numbering shape and the separator */
  z-index: 3;
  width: 35px;
  /* Adjust the width of the shape as needed */
  height: 35px;
  /* Adjust the height of the shape as needed */
  background-color: #171719;
  /* Adjust the background color as needed */
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
  position: absolute;
}

.mobnumber {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}


.mobseparator {
  width: 100%;
  display: flex;
  opacity: 1;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #171719;
  align-self: center;
  margin-bottom: 0;
  filter: drop-shadow(1px 0px 4px rgba(219, 197, 152, 0.60));
  z-index: 2;
}

.mobseparator2 {
  width: 92%;
  display: flex;
  opacity: 1;
  border-top-width: 3px;
  border-top-style: solid;
  border-top-color: #171719;
  align-self: center;
  margin-bottom: 0;
  filter: drop-shadow(1px 0px 4px rgba(219, 197, 152, 0.60));
  z-index: 2;
}

.mobframe {
  padding-left: 1%;
  padding-right: 1%;
  z-index: 3;
  background-color: #171719;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  width: 70%;
  height: 60px;

  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobframe2 {
  padding-left: 1%;
  padding-right: 1%;
  z-index: 3;
  background-color: #171719;
  color: white;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  width: 70%;
  height: 60px;

  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;

  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobframe h3 {

  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mobframe2 h3 {

  color: var(--White-500, #F1E3C5);
  text-align: center;
  /* Quick Sand/20/Semi Bold */
  font-family: Cairo Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
 .backgroundImage {
   height: 100%;
 }

 .CarouselCont {
   height: 100vh;
 }

 .arrowsContainer {

   display: flex;
   flex-direction: row;
   position: absolute;
   margin: 92vh 0% 0% 85.7vw;
   top: 0
 }

 .leftarrow {
   /* position: absolute;
margin: 92vh 0% 0% 84.1vw; */
   z-index: 2;
   background: url('/public/leftarrow.svg');
   width: 36px;
   /* Adjust the width and height based on your SVG dimensions */
   height: 36px;
   border: none;
   /* Remove default button border */
   cursor: pointer;
   /* Add a pointer cursor for better UX */

 }

 .rightarrow {
   z-index: 2;
   /* position: absolute;
  margin: -8vh 0% 0% 92.355vw; */
   background: url('/public/rightarrow.svg');
   width: 36px;
   /* Adjust the width and height based on your SVG dimensions */
   height: 36px;
   border: none;
   /* Remove default button border */
   cursor: pointer;
   /* Add a pointer cursor for better UX */

 }

 .slidecontainer {
   display: flex;
   margin-top: 20%;
   height: 100%;
   width: 100%;
 }

 .leftCont {
   width: 60%;
   height: 100%;
   margin-left: 15%;
 }

 .rightCont {
   width: 50%;
   height: 100%;
   display: flex;
   flex-direction: column;
   margin-right: 15%;
 }

 .lefttopCont {
   width: 100%;
   height: 42%;
 }

 .leftbottomCont {
   display: flex;
   width: 100%;
   height: 50%;
 }

 .righttopCont {
   justify-content: center;

   display: flex;
   width: 100%;
   height: auto;
 }

 .rightbottomCont {
   display: flex;

   width: 100%;
   height: 50%;
   justify-content: right;
 }

 .headline h1 {
   margin: 0;
   width: 100%;
   color: var(--White-600, #FFF);
   font-family: Quicksand;
   font-size: 48px;
   font-style: normal;
   font-weight: 400;
   line-height: 90%;
   text-align: left;
   width: auto;
 }

 .headline h2 {
   margin: 0;
   width: 100%;
   color: var(--White-600, #EBD5A8);
   font-family: Quicksand;
   font-size: 48px;
   font-style: normal;
   font-weight: 400;
   line-height: 90%;
   text-align: left;

 }

 .des1 p {
   margin: 0;
   color: var(--White-100, #FFF);
   text-align: left;
   font-family: Quicksand;
   font-size: 25px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
 }

 .iconsCont {
   display: flex;
   width: 33.33%;
   height: 100%;
   flex-direction: column;
 }

 .iconsCont2 {
   display: flex;
   width: 33.33%;
   height: 100%;
   flex-direction: column;
 }

 .iconsCont23 {
   display: flex;
   width: 33.33%;
   height: 100%;
   flex-direction: column;
 }

 .iconsCont22 {
   display: flex;
   width: 33.33%;
   height: 100%;

 }

 .icon {
   height: 35%;
   width: 60%;
   display: flex;
   flex-direction: column;
   align-self: start;
   display: flex;
   align-items: center;
 }

 .icon2 {
   height: 35%;
   width: 60%;
   display: flex;
   flex-direction: column;
   align-self: start;
   display: flex;
   align-items: end;
 }

 .icon img {
   width: 30% !important;
   margin: 0 !important;
   align-self: flex-start;
 }

 .icon2 img {
   width: 30% !important;
   margin: 0 !important;

 }

 .icon p {
   width: 100%;
   height: 22%;
   color: var(--Sand-200, #DDB96E);
   margin: 0;
   /* Quick Sand/20/Semi Bold */
   font-family: Quicksand;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-align: left;
   margin-top: 0px !important;
 }

 .icon2 p {
   width: 100%;
   height: 22%;
   color: var(--Sand-200, #DDB96E);
   margin: 0;
   /* Quick Sand/20/Semi Bold */
   font-family: 'Cairo Light';
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: normal;
   text-align: right;
   margin-top: 0px !important;
 }

 .righttopCont img {

   width: 85% !important;
   height: auto;
   margin-left: 10%;
 }

 .des2 {
   width: 88%;
 }

 .des2 p {
   margin: 0;
   margin-top: 30px !important;
   color: var(--White-100, #FFF);
   text-align: left;
   font-family: Quicksand;
   font-size: 25px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   width: 100%;
 }

 .des22 {
   width: 88%;
 }

 .des22 p {
   margin: 0;
   margin-top: 30px !important;
   color: var(--White-100, #FFF);
   text-align: right;
   font-family: 'Cairo Light';
   font-size: 25px;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   width: 100%;
 }

 .pageNumber {
   color: var(--Gray-300, #8D8F91);

   /* Quick Sand/24/Medium */
   font-family: Quicksand;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   background-color: transparent;
   border: transparent;
   cursor: auto !important;
   padding-left: 4px !important;
   padding-right: 4px !important;

 }

 .selectedPage {

   color: var(--White-200, #FCF8F1);
   /* Quick Sand/24/Medium */
   font-family: Quicksand;
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   background-color: transparent;
   border: transparent;
   cursor: auto !important;
 }

 .arrows {
   z-index: 2;
   /* position: absolute;
  margin: 91.6vh 0% 0% 86.5%; */
   margin-left: 23%;
   width: 128px;
   border: none;
   /* Remove default button border */

 }

 @media (max-width: 1024px) {

   /* Styles for devices with a maximum width of 600px (mobile devices) */
   .mobPageContainer {
     display: flex !important;
   }

   .pageContainer {
     display: none !important;
   }
 }

 @media (min-width: 1024px) {

   .mobPageContainer {
     display: none !important;
   }

   .pageContainer {
     display: flex !important;

   }
 }

 .mobPageContainer {
   position: relative;
   background: linear-gradient(to bottom, #000000, #53535380);

   display: flex;
   flex-direction: column;
 }

 .submobPageContainer {
   position: relative;
   z-index: 1;
   /* Ensure content appears above the background image */
   margin-top: 17vh;
   display: flex;
   flex-direction: column;

 }

 .mobTitle h1 {
   margin-left: 1.25rem;
   margin-right: 1.25rem;
   color: var(--White-100, #FFF);
   width: 100%;
   /* Quick Sand/36/Regular */
   font-family: Quicksand;
   font-size: 2.25rem;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
 }

 .mobTitle2 h1 {

   padding-right: 1.25rem;
   color: var(--White-100, #FFF);
   width: 100%;
   /* Quick Sand/36/Regular */
   font-family: Cairo Light;
   text-align: right;
   font-size: 2.25rem;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
 }

 .imagesCont img {
   width: 50vw;
 }

 .imagesCont {
   gap: 5%;
   margin-left: 5%;
   margin-top: 3%;

 }

 .mobText {
   margin-left: 1.25rem;
   margin-right: 1.25rem;
   height: 100%;
   padding-top: 20px;
   padding-bottom: 20px;

   width: 92%;
   display: flex;

   flex-direction: column;
 }

 .mobHead1 {
   color: var(--White-100, #FFF);

   font-family: Quicksand;
   font-size: 1.5rem;

   font-style: normal;
   font-weight: 400;
   line-height: 90%;

 }

 .mobHead12 {
   color: var(--White-100, #FFF);

   font-family: Cairo Light;
   text-align: right;
   font-size: 1.5rem;

   font-style: normal;
   font-weight: 400;
   line-height: 90%;

 }

 .mobHead2 {
   color: var(--White-100, #EBD5A8);

   font-family: Quicksand;
   font-size: 1.5rem;
   font-style: normal;
   font-weight: 400;
   line-height: 90%;


 }

 .mobHead22 {
   color: var(--White-100, #EBD5A8);
   font-family: Cairo Light;
   text-align: right;
   font-size: 1.5rem;
   font-style: normal;
   font-weight: 400;
   line-height: 90%;


 }

 .mobDisCont {
   display: flex;

 }

 .mobDisCont p {
   color: var(--White-100, #FFF);
   padding-top: 3%;
   /* Quick Sand/18/Regular */
   font-family: Quicksand;
   font-size: 1.125rem;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   margin: 0 !important;
   width: 100%;
 }

 .mobDisCont2 {
   display: flex;

 }

 .mobDisCont2 p {
   color: var(--White-100, #FFF);
   padding-top: 3%;
   /* Quick Sand/18/Regular */
   font-family: Cairo Light;
   text-align: right;
   font-size: 1.125rem;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   margin: 0 !important;
   width: 100%;
 }

 .iconsCont {
   margin-left: 1.25rem;
   margin-right: 1.25rem;
   width: 50%;
 }

 .iconsCont img {
   width: 2.5rem;
   height: 2.5rem;

 }

 .iconsCont2 {
   margin-left: 2.25rem;

   width: 50%;
 }

 .iconsCont2 img {
   width: 2.5rem;
   height: 2.5rem;

 }

 .iconsCont23 {
   margin-right: .8rem;
   width: 50%;
   align-items: end;
   width: 50%;
 }

 .iconsCont23 img {
   width: 2.5rem;
   height: 2.5rem;

 }

 .iconsCont22 {
   margin-left: 2.25rem;
   justify-content: end;
   width: 50%;
 }

 .iconsCont22 img {
   width: 2.5rem;
   height: 2.5rem;

 }

 .iconsCont h1 {
   color: var(--Sand-200, #DDB96E);

   /* Quick Sand/14/Medium */
   font-family: Quicksand;
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 500;
   line-height: normal;

 }

 .iconsCont2 h1 {
   color: var(--Sand-200, #DDB96E);

   /* Quick Sand/14/Medium */
   font-family: Quicksand;
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 500;
   line-height: normal;

 }

 .iconsCont23 h1 {
   color: var(--Sand-200, #DDB96E);

   /* Quick Sand/14/Medium */
   font-family: Cairo Light;
   text-align: right;
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 500;
   line-height: normal;

 }

 .iconsCont22 h1 {
   color: var(--Sand-200, #DDB96E);

   /* Quick Sand/14/Medium */
   font-family: Quicksand;
   font-size: 0.875rem;
   font-style: normal;
   font-weight: 500;
   line-height: normal;

 }
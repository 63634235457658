html,
body {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  /* Optional: Set a default font family */
  color: #000;
  background: #000;
  background-color: #000 !important;
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #000000, #53535380);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
}

@font-face {
  font-family: 'Cairo Light';
  src: local('Cairo Light'), url('/public/fonts/Cairo-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Cairo Medium';
  src: local('Cairo Medium'), url('/public/fonts/Cairo-Medium.ttf') format('truetype');
}
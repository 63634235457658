.bg {

    background-image: url('/public/Exporting\ background.svg');
    background-size: cover;
    min-height: 100vh;
}

.overlay {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    z-index: 1;
}

.Cont {
    display: flex;
    height: 100%;
    /* margin-top: 10%; */
    width: 100%;
}




.finance {
    width: 50%;
    align-self: center;


}

.finance2 {
    width: 50%;
    align-self: center;


}

.Exportingicons {

    width: 50% !important;

}

.Exportingicons img {
    height: 75%;
}

.mainTitleWhite {
    color: #ffffff;
    font-family: Quicksand;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    width: 100%;
}

.mainTitleWhite2 {
    color: #ffffff;
    font-family: Cairo Light;
    text-align: right;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 100%;
}

h1 {
    width: 90%;

}

.mainTitleYellow {
    color: #EBD5A8;
    font-family: Quicksand;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    width: 100%;

}

.mainTitleYellow2 {
    color: #EBD5A8;
    font-family: Cairo Light;
    text-align: right;
    font-size: 90px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 100%;

}

.finance p {
    color: white;
    font-family: Quicksand;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;

    width: 100%;
    margin-left: 40px;
    box-sizing: 682px !important;
    margin: 0 !important;
    margin-top: 45px !important;
}

.finance2 p {
    color: white;
    font-family: Cairo Light;
    text-align: right;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;

    width: 100%;
    margin-left: 40px;
    box-sizing: 682px !important;
    margin: 0 !important;
    margin-top: 45px !important;
}

.financeCont {
    /* margin-left: 13.5%; */
    width: 70%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 50vh;
    gap: 5%;
    margin-top: 10%;
}


.Exportingicons {

    width: 50% !important;
    position: absolute;
    left: 65vw;
}

.Exportingicons img {
    height: 75%;
}

.ContRibbon {
    animation: animate2 40s linear infinite;
    animation-delay: -20s;
    white-space: nowrap;
    display: flex;
    align-items: center;

}

.ribbon {
    opacity: 60%;
    cursor: default !important;
    position: fixed;
    left: -3.4em;
    z-index: 9999;
    width: 300em;
    /* Adjust the width as needed */
    height: 2em;
    overflow: hidden;
    background-color: none;
    background-color: #00000020;
    backdrop-filter: blur(5px);
    /* border-style: dashed;
    border-width: 2px; */
    gap: 4vw;
    display: flex;
    align-items: center;
    box-sizing: content-box;
    line-height: 1em;
    color: var(--Sand-100, #E4C78B);
    font-family: Quicksand;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
    font-weight: normal;
    top: auto;
    bottom: -1em;
    transform: rotate(6deg) translateY(35px) translateX(20px);
    box-shadow: 0 -5px -15px #00000080;
    animation: scroll 10s linear 1;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%2300000020' stroke='%23E4C78B' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");


}


.ribbon2 {
    opacity: 60%;
    cursor: default !important;
    gap: 4vw;
    display: flex;
    position: fixed;
    left: -3.4em;
    z-index: 9999;
    width: 300em;
    height: 2em;
    overflow: hidden;
    /* background-color: #00000020; */
    /* border: dashed; */
    /* border-spacing:50px 50px; 
    border-width: 2px;
    stroke-dasharray: 8em; */
    align-items: center;
    backdrop-filter: blur(5px);
    box-sizing: content-box;
    line-height: 2em;
    color: #DDB96E;
    font-family: Quicksand;
    font-size: 1em;
    text-align: center;
    text-decoration: none;
    font-weight: normal;
    top: auto;
    bottom: 21em;
    transform: rotate(-8deg) translateY(-3px) translateX(-100px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    animation: animateRibbon2 10s linear 1;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%2300000020' stroke='%23E4C78B' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.ribbon2 span {
    color: var(--Sand-100, #E4C78B);

}

.ribbon span {
    color: var(--Sand-100, #E4C78B);

}

@keyframes animateRibbon2 {
    0% {
        transform: rotate(-8deg) translateY(-3px) translateX(-20%);
    }

    100% {
        transform: rotate(-8deg) translateY(-3px) translateX(0%);
    }
}


@keyframes scroll {
    0% {
        transform: rotate(7deg) translateY(35px) translateX(20px);
    }

    100% {
        transform: rotate(7deg) translateY(35px) translateX(-20%);
    }
}

.buttonContainer {
    display: flex;
    justify-content: end;

    width: 50%;
    margin: 0 !important;
    padding: 0 !important;

}

.buttonContainer2 {
    display: flex;
    justify-content: start;
    flex-direction: row-reverse;
    width: 50%;
    margin: 0 !important;
    padding: 0 !important;
    align-items: center;

}

.childButton2 {
    transform: scaleX(-1);
}

.button {
    margin: 0;
    background: none;
    border: none;
    color: #E4C78B;
    font-family: Quicksand;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 100%;
}

.button2 {
    margin: 0;
    background: none;
    border: none;
    color: #E4C78B;
    font-family: Cairo Light;
    text-align: right;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 100%;
}
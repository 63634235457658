.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms, transform 300ms;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

.arrows {
  z-index: 99;
  position: absolute;
  top: 50vh;
  left: 50px;
}

.TopArrow {
  background-image: url('/public/leftarrow.svg');
  background-size: cover;
  transform: rotate(90deg);
  height: 38px;
  width: 38px;
  background-color: transparent;
  border: none;
}

.bottomArrow {
  background-image: url('/public/rightarrow.svg');
  transform: rotate(90deg);
  height: 38px;
  width: 38px;
  background-color: transparent;
  border: none;
  background-size: cover;
}

.dots {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 130px;

}

.dot {
  cursor: pointer;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 2px;
  width: 9px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.20);
}

.active-dot {
  background-color: #EBD5A8;
  height: 44px !important;
  width: 9px;
  border-radius: 2px;
}

/* .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
  color: white !important;
}
.css-lpvpu0-MuiInputBase-root-MuiInput-root-MuiSelect-root::after{
  border-bottom:  .5px solid #DDB96E !important;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
color: white !important;
}
 
.css-lpvpu0-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.637) !important;
}

.css-lpvpu0-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover::before {
  border-bottom: 0.5px solid rgb(0, 0, 0) !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root{
  color:white !important;
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
color: white !important;
} */
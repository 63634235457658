.overpageCont {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
}

.pageContainer {
  background-image: url('/public/Partners\ background.png');
  /* Adjust the path accordingly */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* This makes the background fixed */
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.CarouselCont {
  height: 100vh;
}

.arrowsContainer {

  display: flex;
  flex-direction: row;
  position: absolute;
  margin: 92vh 0% 0% 87.7vw;
  top: 0
}

.arrowsContainer2 {

  display: flex;
  flex-direction: row;
  position: absolute;
  margin: 92vh 0% 0% 10vw;
  top: 0
}

.leftarrow {
  /* position: absolute;
  margin: 92vh 0% 0% 84.1vw; */
  z-index: 2;
  background: url('/public/leftarrow.svg');
  width: 36px;
  /* Adjust the width and height based on your SVG dimensions */
  height: 36px;
  border: none;
  /* Remove default button border */
  cursor: pointer;
  /* Add a pointer cursor for better UX */

}

.rightarrow {
  z-index: 2;
  /* position: absolute;
  margin: -8vh 0% 0% 92.355vw; */
  background: url('/public/rightarrow.svg');
  width: 36px;
  /* Adjust the width and height based on your SVG dimensions */
  height: 36px;
  border: none;
  /* Remove default button border */
  cursor: pointer;
  /* Add a pointer cursor for better UX */

}


.pageNumber {
  color: var(--Gray-300, #8D8F91);

  /* Quick Sand/24/Medium */
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: transparent;
  cursor: auto !important;
  padding-left: 4px !important;
  padding-right: 4px !important;

}

.pageNumber2 {
  color: var(--Gray-300, #8D8F91);
  padding-bottom: 10px;
  /* Quick Sand/24/Medium */
  font-family: Cairo Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: transparent;
  cursor: auto !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  display: flex;
  align-items: center;

}

.selectedPage {

  color: var(--White-200, #FCF8F1);
  /* Quick Sand/24/Medium */
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: transparent;
  cursor: auto !important;
}

.selectedPage2 {

  color: var(--White-200, #FCF8F1);
  padding-bottom: 10px;
  font-family: Cairo Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: transparent;
  cursor: auto !important;
  display: flex;
  align-items: center;
}

.arrows {
  z-index: 2;
  /* position: absolute;
  margin: 91.6vh 0% 0% 86.5%; */
  margin-left: 34%;
  width: 73px;
  border: none;
  /* Remove default button border */

}

.arrows2 {
  z-index: 2;
  /* position: absolute;
  margin: 91.6vh 0% 0% 86.5%; */
  margin-left: 34%;
  width: 73px;
  border: none;
  /* Remove default button border */
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

}

.lefttopCont {
  z-index: 5;
  position: absolute;
  top: 21%;
  left: 14%;
  display: flex;
  width: 23%;
}

.lefttopCont22 {
  z-index: 5;
  position: absolute;
  top: 18%;
  right: 16%;
  display: flex;
  width: 35%;
  justify-content: end;
}

.PartTitles h1 {
  color: var(--White-100, #FFF);
  text-align: right;
  font-family: Cairo Light;
  font-size: 8rem;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  /* 9.6rem */
}

.rightbottomCont {
  z-index: 5;
  position: absolute;
  top: 65%;
  left: 52%;
  display: flex;
  width: 36%;
  height: 25%;
}

.rightbottomCont2 {
  z-index: 5;
  position: absolute;
  top: 65%;
  right: 52%;
  display: flex;
  width: 36%;
  height: 25%;
}

.headline h1 {

  margin: 0;
  width: 100%;
  color: var(--White-600, #FFF);
  font-family: Cairo Light;
  font-size: 110px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-align: right;
  width: auto;
}

.headline h2 {
  margin: 0;
  width: 100%;
  color: var(--White-600, #EBD5A8);
  font-family: Cairo Light;
  font-size: 110px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: right;

}

.leftbottomCont {
  z-index: 6;
  display: flex;
  width: 100%;
  height: 50%;
  margin-top: 34vh;
  flex-direction: column;

}

.SelectedPartnerbutton {

  background-color: #171719;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  font-family: Quicksand;
  line-height: auto;
  border-style: hidden;
  outline: none;
  width: 155px;
  height: 40px;
  --d: 9px !important;
  --b: 4px !important;
  --c: orange !important;
  --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
  --gl: var(--c), #0000 40% 60%, var(--c) !important;
  clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
  border-radius: 12px;
}

.unSelectedPartnerbutton {
  background-color: #1E1F21;
  color: #F5EAD4;
  text-align: center;
  font-weight: lighter;
  font-size: 20px;
  font-family: Quicksand;
  line-height: auto;
  border-style: hidden;
  outline: none;
  width: 160px;
  height: 40px;
  --d: 9px !important;
  --b: 4px !important;
  --c: orange !important;
  --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
  --gl: var(--c), #0000 40% 60%, var(--c) !important;
  clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
  border-radius: 12px;
  display: flex;
  align-content: center;
  padding: 5px;
  justify-content: center;
}

.unSelectedPartnerbutton:hover {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.25);
  stroke: #DDB96E;
  color: #DDB96E !important;
  cursor: pointer;
  text-align: center;
  font-weight: lighter;
  font-size: 20px;
  font-family: Quicksand;
  line-height: auto;
  border-style: hidden;
  outline: none;
  width: 160px;
  height: 40px;
  --d: 9px !important;
  --b: 4px !important;
  --c: orange !important;
  --gc: var(--c) calc(50% + var(--b) * 0.707), #0000 0 !important;
  --gl: var(--c), #0000 40% 60%, var(--c) !important;
  clip-path: polygon(var(--d) 0, calc(100% - var(--d)) 0, 100% var(--d), 100% calc(100% - var(--d)), calc(100% - var(--d)) 100%, var(--d) 100%, 0 calc(100% - var(--d)), 0 var(--d));
  border-radius: 12px;
  display: flex;
  align-content: center;
  padding: 5px;
  justify-content: center;
}

.Subcontpartners {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.contpartners {
  display: flex;
  gap: 25px;
  align-self: center;
}

.slidecontainer {
  display: flex;
  margin-top: 20%;
  height: 100%;
  width: 100%;
}

.slidecontainer2 {
  display: flex;
  margin-top: 20%;
  height: 100%;
  width: 100%;
  flex-direction: row-reverse;
}

.righttopCont {


  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.leftCont {
  width: 50%;
  height: 100%;
  margin-left: 11%;
}

.leftCont img {

  width: vw;
}

.leftCont2 {
  width: 50%;
  height: 100%;
  margin-right: 11%;
}

.rightCont {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 11%;
}

.rightCont2 {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 6%;
}

.des {
  display: flex;
  flex-direction: row;
  gap: 22px;
}

.des22 {
  display: flex;
  flex-direction: row-reverse;
  gap: .1vw;
}

.des1 {
  display: flex;
  flex-direction: column;
}

.des1 h1 {
  color: var(--White-100, #FFF);
  font-family: Quicksand;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: center;
  width: 100%;
}

.des1 h2 {
  color: var(--White-600, #EBD5A8);
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.des122 {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-content: center;
  align-items: center;

}

.des122 h1 {
  color: var(--White-100, #FFF);
  font-family: Cairo Light;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: right;
  width: fit-content;
  height: 60%;
}

.des122 h2 {
  color: var(--White-600, #EBD5A8);
  font-family: Cairo Light;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}


.des2 {
  display: flex;

}

.des2 h1 {
  width: 100%;
  margin: 0;
  color: var(--White-100, #FFF);
  font-family: Quicksand;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.des222 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

}

.des222 h1 {
  width: 100%;
  margin: 0;
  color: var(--White-100, #FFF);
  font-family: Cairo Light;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  text-align: right;
  height: fit-content;
}

.lefttopCont2 {
  display: flex;

}

.partnerlogo img {
  top: -29px !important;
  position: absolute;
  width: 100%;
}

.partnerlogo {
  display: flex;
  width: auto;
}
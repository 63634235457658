.pageContainer {
    background-image: url('/public/Partners\ background.png');
    /* Adjust the path accordingly */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /* This makes the background fixed */
    width: 100% !important;
    height: 100% !important;
    position: absolute;
}

.homecontiner {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    height: 100%;

}

.leftCont {
    width: 30%;
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center;

}

.rightCont {
    width: 35%;
    flex-direction: column;
    display: flex;

}

/* Place this text at the top of your CSS file */
.text {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
}

.text h1 {
    color: #ffffff;
    /* Quick Sand/24/Medium */
    font-family: Quicksand;

    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.flipped {
    transform: scaleX(-1);
}

.text2 {

    align-items: center;
    display: inline;
}

.text2 h1 {
    color: #ffffff;
    /* Quick Sand/24/Medium */
    font-family: Quicksand;
    display: inline;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

/* .firstleftContiner button {
    background-image:url()
} */

.fristleftContiner {
    display: flex;
    flex-direction: row;
}

.iconButton {
    height: 100%;
}

@media(max-width:768px) {
    .mobileContainer {
        flex-direction: column !important;
    }


}

.info {
    width: 50vw;
    color: var(--White-100, #DDB96E);
    font-family: Cairo Light;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    /* Center the text inside the element */
}

.logoContainer {
    background-image: url('/public/Partners/logoBG.svg');
    /* Adjust the path to your logobg.svg */
    background-size: contain;
    /* Ensures the background image fits within the container */
    background-repeat: no-repeat;
    /* Prevents repeating the background */
    background-position: center;
    /* Centers the background image */
    width: 20vw;
    /* Width of your logo image */
    height: 20vw;
    /* Height of your logo image */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* Ensures z-index works */
}

.logoImage {
    width: 60%;
}